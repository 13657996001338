u1 {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: white;
  text-decoration: none;
}

.navbar {
  padding: 0 1rem;
  max-height: 100%;
}

.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.nav-item {
  width: calc(100% * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button {
  margin: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.dropdown {
  position: absolute;
  top: 90px;
  width: 200px;
  transform: translateX(-85%);
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;
  background: #3f3f3f;
  z-index: 1;
  height: auto;
  /* max-height: 220px; */
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  transition: background 500ms;
  padding: 0.5rem;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item .icon-button:hover {
  background-color: #828282;
}

.icon-right {
  margin-left: auto;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}

#logout-item {
  height: 50px;
  display: flex;
  align-items: center;
  transition: background 500ms;
  padding: 0.5rem;
  cursor: pointer;
}
