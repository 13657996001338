.rec.rec-dot {
  background: #828282;
  border: 0px;
  box-shadow: 0 0 0 0;
}
.rec.rec-dot_active {
  background: #26a2b1;
  border-color: #26a2b1;
  border: 0px;
  box-shadow: 0 0 0 0;
}

/* tabs */
.react-tabs__tab {
  font-size: 20px;
  text-align: center;
  color: #26a2b1;
  font-weight: bold;
  box-shadow: 0 -1px 0 0.5px #bbb;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--selected {
  border-color: #26a2b1;
  box-shadow: #26a2b1 0px -1px 0px 1px !important;
}

.rshJq:hover {
  box-shadow: 0 0 0 0 !important;
}

.rshJq:focus {
  box-shadow: 0 0 0 0 !important;
}

tr td:last-child,
tr th:last-child {
  text-align: center;
  max-width: 145px !important;
}
