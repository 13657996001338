.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(248, 248, 248, 0.9);
  padding: 10px;
  text-align: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;

}

.cookie-consent-banner p {
  color: black;
}

.cookie-consent-banner button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.cookie-consent-banner .button-accept {
  background-color: #26A2B1;
  color: white;
}

.cookie-consent-banner .button-decline {
  background-color: transparent;
  color: black;
  border: 1px solid black;
}

.link-text {
  color: black;
}

.cookie-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #f8f8f8;
  padding: 20px;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}
