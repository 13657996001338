.swal2-title {
  font-family: 'InterSemiBold';
  font-size: 1.5em;
}

.swal2-html-container {
  font-family: 'InterSemiBold';
  font-size: 1.875em;
  font-weight: 600;
}
