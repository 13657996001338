/* Estilos Autocomplete */
/* Início */

.MuiFilledInput-underline:before {
  border: 0 !important;
}

.MuiFilledInput-underline:after {
  border: 0 !important;
}

#btnExcluir {
  color: #fff;
  background-color: red;
  font-weight: bold;
  font-family: 'InterSemiBold', sans-serif;
  text-transform: capitalize;
  width: 85px;
}

#btnExcluir:hover {
  opacity: 0.8;
}

#btnEditar {
  color: #333;
  font-weight: bold;
  font-family: 'InterSemiBold', sans-serif;
  text-transform: capitalize;
}

#btnEditar::before {
  content: '\00270E';
  color: #333;
  font-size: 20px;
  margin-right: 5px;
}

.MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  color: #828282 !important;

  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
}

.MuiFilledInput-root {
  background-color: transparent !important;
}

.MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']
  .MuiAutocomplete-input {
  padding: 15px 15px !important;
}

.MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']
  .MuiAutocomplete-endAdornment {
  left: 490px !important;
  top: 0 !important;
}

div.MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth > label {
  font-size: 16px !important;
  color: #828282 !important;
  margin: 0;
  padding: 0;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
  display: none;
}

/* Fim */

/* Estilos Select */
/* Início */
.css-1okebmr-indicatorSeparator {
  margin: 0 0 12px 0 !important;
}
/* Fim */
